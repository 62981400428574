import React, { useState, useEffect, useRef, useContext } from "react";
import "./navbar.scss";
import { Link, useLocation } from "react-router-dom";
import $ from "jquery";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import { Sling as Hamburger } from "hamburger-react";
import { DarkModeContext } from "../../../Context/darkModeContext";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";

const Navbar = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [active, setActive] = useState();
  const [isOpen, setOpen] = useState(false);
  const menuRef = useRef();
  const { dispatch, darkMode } = useContext(DarkModeContext);

  const { pathname } = useLocation();

  const isActive = () => {
    window.scrollY > 42 ? setActive(true) : setActive(false);
  };

  const handleToggle = () => {
    setOpen(!isOpen);
    if (!isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", isActive);

    return () => {
      window.removeEventListener("scroll", isActive);
    };
  }, []);

  const handleClick = () => {
    setOpen(false);
  };

  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      $(menuRef.current).slideDown(300, function () {
        $(this).css({ display: "block" });
        document.body.style.overflow = "hidden";
      });
    } else {
      $(menuRef.current).slideUp(300, function () {
        $(this).css({ display: "none" });
        document.body.style.overflow = "auto";
      });
    }
  }, [isOpen]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };

  const handleDarkMode = () => {
    dispatch({ type: "TOGGLE" });
  };

  return (
    <div
      className={`nav-wrap ${active || pathname !== "/" ? "active" : ""} ${
        pathname !== "/" ? "not-homepage" : ""
      }`}
    >
      <div className="container-nav">
        {screenWidth > 768 ? (
          <nav className="nav">
            <ul className="nav-items">
              <Link className="link" to="/" onClick={scrollToTop}>
                {" "}
                <li> Startseite</li>
              </Link>
              <Link className="link" to="/über" onClick={scrollToTop}>
                {" "}
                <li> Über uns</li>
              </Link>
            </ul>
            <ul className="nav-items-heading">
              <p className="heading">Restaurant Opatija</p>
            </ul>
            <ul className="nav-items">
              <Link className="link" to="/menü" onClick={scrollToTop}>
                {" "}
                <li> Menü</li>
              </Link>
              <Link className="link" to="/kontakt" onClick={scrollToTop}>
                {" "}
                <li> Kontakt</li>
              </Link>
            </ul>
          </nav>
        ) : (
          <nav className="nav-mobile">
            <div className="nav-wrap-mobile">
              <div className={`hamburger-react ${isOpen ? "open" : ""}`}>
                <Hamburger
                  onToggle={handleToggle}
                  toggled={isOpen}
                  duration={1}
                  className="hamburger-react"
                />
              </div>
              <ul className={`nav-items-heading ${isOpen ? "open" : ""}`}>
                <p className="heading">Restaurant Opatija</p>
              </ul>
            </div>
            <div
              ref={menuRef}
              className={`menu ${isOpen ? "active" : ""}`}
              style={{ display: "none" }}
            >
              <ul className="nav-items" onClick={handleClick}>
                <Link className="link" to="/" onClick={scrollToTop}>
                  {" "}
                  <li> Startseite</li>
                </Link>
                <Link className="link" to="/über" onClick={scrollToTop}>
                  {" "}
                  <li> Über uns</li>
                </Link>
                <Link className="link" to="/menü" onClick={scrollToTop}>
                  {" "}
                  <li> Menü</li>
                </Link>
                <Link className="link" to="/kontakt" onClick={scrollToTop}>
                  {" "}
                  <li>Kontakt</li>
                </Link>
              </ul>

              <ul className="nav-items-icon">
                <div className="location">
                  <span class="material-icons-outlined">
                    {" "}
                    <span onClick={handleDarkMode}>
                      {darkMode ? <LightModeOutlinedIcon /> : <DarkModeIcon />}
                    </span>
                  </span>
                </div>
                <span class="material-icons-outlined">
                  {" "}
                  <InstagramIcon />{" "}
                </span>
                <span class="material-icons-outlined">
                  {" "}
                  <FacebookIcon />{" "}
                </span>
                <span class="material-icons-outlined">
                  {" "}
                  <TwitterIcon />{" "}
                </span>
              </ul>
            </div>
          </nav>
        )}
      </div>
    </div>
  );
};

export default Navbar;
