import "./testimonial.scss";
import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { MdArrowBackIosNew, MdArrowForwardIos } from "react-icons/md";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import required modules
import { Autoplay, Navigation, Pagination } from "swiper/modules";

const Testimonial = () => {
  return (
    <div className="testimonial">
      <div className="container">
        <Swiper
          navigation={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={true}
          modules={[Navigation, Pagination, Autoplay]}
          className="mySwiper"
        >
          {/* <SliderButtons /> */}
          <SwiperSlide>
            <div className="swiper">
              <h2>
                “Ein Abend im Restaurant Opatija ist stets ein kulinarisches
                Highlight! Die raffinierte Auswahl an Gerichten aus frischen,
                hochwertigen Zutaten begeistert mich immer wieder. Der
                exzellente Service und das ansprechende Ambiente machen jeden
                Besuch zu einem unvergesslichen Erlebnis..”
              </h2>
              <p>Bleon H., 2024</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="swiper">
              <h2>
                “Das Restaurant Opatija hat meine Erwartungen übertroffen. Die
                geschmackvolle Kombination von traditionellen Aromen in jedem
                Gericht zeigt die Hingabe des Küchenteams. Jeder Besuch ist eine
                Reise durch exquisiten Geschmack und ein Fest für die Sinne.”
              </h2>
              <p>Fidan I., 2023</p>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="swiper">
              <h2>
                “Die Atmosphäre im Restaurant Opatija ist einfach zauberhaft.
                Die sorgfältig zusammengestellte Speisekarte, gepaart mit
                erstklassigem Service, macht diesen Ort zu meinem Favoriten.
                Hier wird Essen zu einer wahren Genussreise.”
              </h2>
              <p>André M., 2023</p>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default Testimonial;

const SliderButtons = () => {
  const swiper = useSwiper();
  return (
    <div className="swiper-buttons">
      <div className="r-buttons ">
        <button onClick={() => swiper.slidePrev()}>
          {" "}
          <MdArrowBackIosNew />
        </button>
        <button onClick={() => swiper.slideNext()}>
          <MdArrowForwardIos />
        </button>
      </div>
    </div>
  );
};
