import React, { useState } from "react";
import "./section2.scss";

const Section2 = () => {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const isAnyTabActive = activeTab !== 0;

  return (
    <div className="menu-section-2">
      <div className="container">
        <div className="menu-wrapper__section-2">
          <div className="menu__section-2">
            <div className="menu__section-2__text">
              <h1>Speisekarte</h1>
            </div>

            <div className="vl"></div>
            <div className="tabs">
              <div className="item">
                <span
                  className={activeTab === 1 ? "tab vh" : "tab"}
                  onClick={() => handleTabClick(1)}
                >
                  Menü 1
                </span>

                <span
                  className={activeTab === 2 ? "tab vh" : "tab"}
                  onClick={() => handleTabClick(2)}
                >
                  Menü 2
                </span>
                <span
                  className={activeTab === 3 ? "tab  vh" : "tab"}
                  onClick={() => handleTabClick(3)}
                >
                  Menü 3
                </span>
              </div>
            </div>
            <div className="menu__section-2__food">
              {activeTab === 1 && (
                <div className="menu-items-1 active-wrapper">
                  <div className="menu-items-wrapper">
                    <div className="text-items">
                      <h2>Vorspeisen</h2>
                      <p>
                        Bestellen Sie für den Tisch und verbreiten Sie die
                        Liebe.
                      </p>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>Gegrillter Feta Käse</span>
                        <span>
                          mit frischen Tomaten und Knoblauch, Öl & Brot
                        </span>
                      </div>
                      <div className="item-price">
                        <span>6,50 €</span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>Edamer Käse' paniert </span>
                        <span>Toast, Sauce Tartare oder Preiselbeeren</span>
                      </div>
                      <div className="item-price">
                        <span>6,50 €</span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span> Crostini mit Gemüsevariation</span>
                        <span> und Sauerrahm</span>
                      </div>
                      <div className="item-price">
                        <span>6,50 €</span>
                      </div>
                    </div>

                    <div className="items">
                      <div className="item">
                        <span>Feuersuppe^ (scharf)</span>
                        <span>Gulaschsuppe, Kidneybohnen & Mais</span>
                      </div>
                      <div className="item-price">
                        <span>5,00 €</span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>Traditionelle Gulaschsuppe</span>
                      </div>
                      <div className="item-price">
                        <span>5,00 €</span>
                      </div>
                    </div>
                  </div>

                  <div className="menu-items-wrapper">
                    <div className="text-items">
                      <h2>Fisch und Garnelen</h2>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>Garnelen mit Ofenkartoffel </span>
                        <span>Grillgemüse, Frischer Salat & Sauerrahm</span>
                      </div>
                      <div className="item-price">
                        <span>14,50 €</span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>Lachsfilet</span>
                        <span>
                          mit Spinat in Rahmsauce, dalmatische Garnitur & Salat
                        </span>
                      </div>
                      <div className="item-price">
                        <span>21,50 €</span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span> Garnelen in Knoblauch-Tomatensauce </span>
                        <span>dalmatische Garnitur, Salat</span>
                      </div>
                      <div className="item-price">
                        <span>23,00 €</span>
                      </div>
                    </div>

                    <div className="items">
                      <div className="item">
                        <span> Tintenfisch-Ringe</span>
                        <span>Paniert, Salat, Sauce Tartare</span>
                      </div>
                      <div className="item-price">
                        <span>14,50 €</span>
                      </div>
                    </div>
                  </div>

                  <div className="menu-items-wrapper">
                    <div className="text-items">
                      <h2>Vegetarische Speisen</h2>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>Opatija Grillgemüse-Teller </span>
                        <span>
                          mit Ofenkartoffel, frischem Grillgemüse, Salat (Öl &
                          Essig)
                        </span>
                      </div>
                      <div className="item-price">
                        <span>14,50 €</span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>Gefüllte Paprika</span>
                        <span>mit Reis, Oliven und getrockneten Tomaten</span>
                      </div>
                      <div className="item-price">
                        <span>14,50 €</span>
                      </div>
                    </div>
                    <div className="text-items">
                      <h2>Pasta</h2>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span> Spaghetti Aglio e Olio</span>
                        <span>
                          Knoblauch, Olivenöl, Peperoni, Oliven & Parmesan &
                          Kirschtomaten mit Garnelen
                        </span>
                      </div>
                      <div className="item-price">
                        <span>10,50 €</span>
                      </div>
                    </div>

                    <div className="items">
                      <div className="item">
                        <span>Spaghetti mit Garnelen</span>
                        <span>in Tomaten-Sahnesauce</span>
                      </div>
                      <div className="item-price">
                        <span>14,50 €</span>
                      </div>
                    </div>
                  </div>
                  <div className="menu-items-wrapper">
                    <div className="text-items">
                      <h2>Salat</h2>
                      <p></p>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>Opatija Salat</span>
                        <span>
                          Frischer Salat, Ei, Oliven, Peperoni, Paprika,
                          Tomaten, Zwiebelringe, Dressing & spezial Brot,
                          wahlweise mit
                        </span>
                      </div>
                      <div className="item-price">
                        <span>11,90 €</span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>- mit Hähnchen oder Thunfisch</span>
                        <span></span>
                      </div>
                      <div className="item-price">
                        <span>12,90 €</span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>-mit Garnelen</span>
                        <span></span>
                      </div>
                      <div className="item-price">
                        <span>15,90 €</span>
                      </div>
                    </div>

                    <div className="items">
                      <div className="item">
                        <span> Hausgemachtter Balkan Salat</span>
                        <span>Krautsdalat, Öl & Essig</span>
                      </div>
                      <div className="item-price">
                        <span>4,50 €</span>
                      </div>
                    </div>
                  </div>

                  <div className="menu-items-wrapper">
                    <div className="text-items">
                      <h2>Schnitzel Spezialitäten</h2>
                      <p></p>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>Schnitzel Wiener Art</span>
                        <span>Pommes Frites & Salat, Sauce nach Wahl</span>
                      </div>
                      <div className="item-price">
                        <span>14,50 €</span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>Schnitzel Cordon Bleu (paniert)</span>
                        <span>Pommes Frites & Salat</span>
                      </div>
                      <div className="item-price">
                        <span>18,00 €</span>
                      </div>
                    </div>
                  </div>
                  <div className="menu-items-wrapper">
                    <div className="text-items">
                      <h2>Pfannen Spezialitäten</h2>
                      <p></p>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>Opatija Hauspfanne</span>
                        <span>
                          Hähnchen, frische Champignons & Zwiebeln, Zucchini,
                          Paprika gebraten in Rahmsauce mit Butterreis & Salat
                        </span>
                      </div>
                      <div className="item-price">
                        <span>17,00 €</span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>Sataras (scharf)</span>
                        <span>Pommes Frites & Salat</span>
                      </div>
                      <div className="item-price">
                        <span>16,50 €</span>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {activeTab === 2 && (
                <div className="menu-items-2 active-wrapper">
                  <div className="menu-items-wrapper">
                    <div className="text-items">
                      <h2>Beilagen</h2>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>Bratkartoffeln </span>
                      </div>
                      <div className="item-price">
                        <span>4,00 €</span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>Djuveēreis </span>
                      </div>

                      <div className="item-price">
                        <span>3,50 €</span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>Butterreis</span>
                      </div>
                      <div className="item-price">
                        <span>3,50 €</span>
                      </div>
                    </div>

                    <div className="items">
                      <div className="item">
                        <span>Kroketten</span>
                      </div>
                      <div className="item-price">
                        <span>3,50 €</span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>Pommes Frites</span>
                      </div>
                      <div className="item-price">
                        <span>3,50 €</span>
                      </div>
                    </div>

                    <div className="items">
                      <div className="item">
                        <span>Frische Champignons</span>
                      </div>
                      <div className="item-price">
                        <span>5,50 €</span>
                      </div>
                    </div>

                    <div className="items">
                      <div className="item">
                        <span>Pfifferlinge</span>
                      </div>
                      <div className="item-price">
                        <span>8,50 €</span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>Champignonrahmsauce</span>
                      </div>
                      <div className="item-price">
                        <span>3,50 €</span>
                      </div>
                    </div>
                  </div>
                  <div className="menu-items-wrapper">
                    <div className="text-items">
                      <h2>Saucen</h2>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>Knoblauchsauce </span>
                      </div>
                      <div className="item-price">
                        <span>3,50 €</span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>Pfeffersauce </span>
                      </div>

                      <div className="item-price">
                        <span>3,50 €</span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>Frischer Knoblauch in Öl</span>
                      </div>
                      <div className="item-price">
                        <span>3,00 €</span>
                      </div>
                    </div>

                    <div className="items">
                      <div className="item">
                        <span>Pepperonisauce</span>
                      </div>
                      <div className="item-price">
                        <span>3,50 €</span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>Sauerrahm</span>
                      </div>
                      <div className="item-price">
                        <span>3,50 €</span>
                      </div>
                    </div>

                    <div className="items">
                      <div className="item">
                        <span>Ketchup</span>
                      </div>
                      <div className="item-price">
                        <span>0,70 €</span>
                      </div>
                    </div>

                    <div className="items">
                      <div className="item">
                        <span>Mayonnaise</span>
                      </div>
                      <div className="item-price">
                        <span>0,70 €</span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>Kräuterbutter</span>
                      </div>
                      <div className="item-price">
                        <span>0,70 €</span>
                      </div>
                    </div>
                  </div>
                  <div className="menu-items-wrapper-coffe">
                    <div className="text-items">
                      <h2>dessert</h2>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>Palačinke </span>
                        <span>
                          2 Stück mit Eis (Vanillegeschmack), mit heißen
                          Himbeeren, Kirschen oder Schokoladesauce
                        </span>
                      </div>
                      <div className="item-price">
                        <span>6,00 €</span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>Eisbecher</span>
                        <span>
                          3 Kugeln Eis (Vanillegeschmack), mit heißen Himbeeren,
                          Kirschen oder Schokoladesauce
                        </span>
                      </div>
                      <div className="item-price">
                        <span>5,50 €</span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>Cupavci</span>
                        <span>
                          Schoko-Kokos-Küchlein aus dem Balkan mit einer Kugel
                          Eis (Vanillegeschmack), und Schokoladesauce
                        </span>
                      </div>
                      <div className="item-price">
                        <span>7,50 €</span>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {activeTab === 3 && (
                <div className="menu-items-lunch active-wrapper">
                  <div className="menu-items-wrapper-lunch">
                    <div className="text-items">
                      <h2>Spezialitäten vom Grill</h2>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>Cevapcici-Burger </span>
                        <span>
                          Cevapcici, Magerspeck & Salat, Pommes Frites
                        </span>
                      </div>
                      <div className="item-price">
                        <span>14,90 €</span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>Steak-Burger </span>
                        <span>
                          gegrillte Paprika, gebratene Zwiebeln, Burgerdip,
                          Salza Sauce, Pommes Frites & Salat
                        </span>
                      </div>
                      <div className="item-price">
                        <span>15,50 €</span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>Cevapcici</span>
                        <span>
                          Magerspeck, Djuvecreis, Pommes Frites & Salat
                        </span>
                      </div>
                      <div className="item-price">
                        <span>14,50 €</span>
                      </div>
                    </div>

                    <div className="items">
                      <div className="item">
                        <span>Bifteki</span>
                        <span>
                          Hackfleisch gefüllt mit Weichkäse, Djuveēreis', Pommes
                          Frites & Salat
                        </span>
                      </div>
                      <div className="item-price">
                        <span>18,50 €</span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>Grill-Leber</span>
                        <span>
                          Rinderleber, Kartoffelpüree, geröstete Zwiebeln,
                          Apfelscheiben & Salat
                        </span>
                      </div>
                      <div className="item-price">
                        <span>15,50 €</span>
                      </div>
                    </div>

                    <div className="items">
                      <div className="item">
                        <span>Opatija Bauern-Teller</span>
                        <span>
                          Hacksteak, Hähnchenschnitzel, gegrillter Feta,
                          Cevapcici, Djuvecreis, Pommes Frites & Salat
                        </span>
                      </div>
                      <div className="item-price">
                        <span>16,50 €</span>
                      </div>
                    </div>

                    <div className="items">
                      <div className="item">
                        <span>Mix Grill-Teller</span>
                        <span>
                          Rumpsteak, Leber, Hacksteak, Hähnchenfilet, paniertes
                          Schnitzel, Magerspeck, Djuvecreis, Pommes Frites &
                          Salat
                        </span>
                      </div>
                      <div className="item-price">
                        <span>18,90 €</span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>Opatija Spezial Grill-Teller</span>
                        <span>
                          Hacksteak, Schweinesteak, Hähnchenfilet, Cevapcici,
                          Magerspeck, Djuvecreis, Pommes Frites & Salat
                        </span>
                      </div>
                      <div className="item-price">
                        <span>17,50 €</span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>Schweinemedaillon-Spieß</span>
                        <span>
                          4 Schweinemedaillons im Speckmantel mit Grillgemüse,
                          Kräuterbutter, Djuvecreis & Salat
                        </span>
                      </div>
                      <div className="item-price">
                        <span>18,50 €</span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>Pfeffer-Spieß</span>
                        <span>
                          2 Rumpsteaks, 2 Schweinefilets, Pfeffersauce, Pommes
                          Frites, Djuvecreis & Salat
                        </span>
                      </div>
                      <div className="item-price">
                        <span>18,50 €</span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span> Opatija Grillplatte</span>
                        <span>
                          für zwei Personen Rumpsteaks, Hähnchenfilets,
                          Hacksteaks, Leber, Cevapeici, Magerspeck, Pommes
                          Frites, Djuvecreis & Salat
                        </span>
                      </div>
                      <div className="item-price">
                        <span>46,00 €</span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>Opatija-Spieß</span>
                        <span>
                          2 Rumpsteak, 2 Schweinefilets, Magerspeck,
                          Grillgemüse, Djuvecreis, Pommes Frites & Salat
                        </span>
                      </div>
                      <div className="item-price">
                        <span>20,50 €</span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>Steak-Teller</span>
                        <span>
                          Rinderfiletsteak, Rumpsteak, Lammfilet, Hähnchenfilet,
                          Magerspeck, Bratkartoffeln mit Speck & Zwiebeln,
                          Kräuterbutter & Salat
                        </span>
                      </div>
                      <div className="item-price">
                        <span>22,50 €</span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>Lammfilets 220g</span>
                        <span>
                          Bratkartoffeln mit Speck & Zwiebeln, Kräuterbutter &
                          Salat
                        </span>
                      </div>
                      <div className="item-price">
                        <span>25,50 €</span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>Lammkarree</span>
                        <span>
                          Bratkartoffeln mit Speck & Zwiebeln, Prinzessbohnen,
                          Kräuterbutter & Salat
                        </span>
                      </div>
                      <div className="item-price">
                        <span>23,00 €</span>
                      </div>
                    </div>
                  </div>
                  <div className="menu-items-steak">
                    <div className="text-items">
                      <h2>Argentinisches Black-Angus-Steaks</h2>
                      <p>Beilagen und Saucen zu jedem Steak nach Wahl</p>
                    </div>
                    <div className="menu-items-wrapper-steak">
                      <div className="items">
                        <div className="item">
                          <div className="item">
                            <span>Rumpsteak</span>
                            <span>
                              {" "}
                              Pommes Frites, Kräuterbutter, Djuvecreis & Salat
                            </span>
                          </div>
                        </div>
                        <div className="item-price-collection">
                          <span>220g</span>
                          <span>300g</span>
                        </div>
                        <div className="item-price-wein">
                          <span>25,00 €</span>
                          <span>29,50 €</span>
                        </div>
                      </div>

                      <div className="items">
                        <div className="item">
                          <div className="item">
                            <span>Entrecotesteak</span>
                            <span>
                              {" "}
                              Rinderfilet, Folienkartoffel, Kräuterbutter &
                              Salat
                            </span>
                          </div>
                        </div>
                        <div className="item-price-collection">
                          <span>220g</span>
                          <span>300g</span>
                        </div>
                        <div className="item-price-wein">
                          <span>27,00 €</span>
                          <span>30,50 €</span>
                        </div>
                      </div>
                      <div className="items">
                        <div className="item">
                          <div className="item">
                            <span>Filet-Steak</span>
                            <span>
                              Rinderfilet, Bratkartoffel mit Speck & Zwiebeln,
                              Kräuterbutter & Salat
                            </span>
                          </div>
                        </div>
                        <div className="item-price-collection">
                          <span>220g</span>
                          <span>300g</span>
                        </div>
                        <div className="item-price-wein">
                          <span>28,50 €</span>
                          <span>32,50 €</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section2;
