import React, { useState, useRef } from "react";
import "./section3.scss";
import kitchen from "../../../static/section3.webp";
import axios from "axios";
import Loading from "../../../utils/loading/Loading";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const Section3 = () => {
  const [result, showResult] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    message: "",
    number: "",
    errors: {},
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const form = useRef();

  const validateForm = () => {
    const errors = {};
    if (!formData.username) {
      errors.username = "Erforderlich Ihr Name";
    }
    if (!formData.email) {
      errors.email = "Erforderlich E-Mail-Adresse";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Ungültige E-Mail-Adresse";
    }

    if (!formData.number) {
      errors.number = "Erforderlich Mobil";
    }
    if (!formData.message) {
      errors.message = "Erforderlich Ihre Nachricht";
    }
    return errors;
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      setLoading(true);
      const formData = new FormData(form.current);
      const data = Object.fromEntries(formData.entries());
      try {
        const response = await axios.post(
          "https://bh-kitchen-dut5.onrender.com/send-email",
          data
        );
        e.target.reset();
        setFormData({
          username: "",
          email: "",
          number: "",
          message: "",
          errors: {},
        });
        showResult(true);
        setTimeout(() => {
          showResult(false);
        }, 5000);
      } catch (error) {
        console.error("Error sending email:", error);
      }
      setLoading(false);
    } else {
      setFormData({
        ...formData,
        errors: errors,
      });
    }
  };

  return (
    <div className="section-3">
      <div className="section-3-wrapper">
        <div className="section-3-image">
          <img src={kitchen} alt="" />
        </div>
        <div className="section-3-text">
          <h3>In Kontakt treten</h3>
          <p>
            Um in Kontakt zu treten, füllen Sie bitte das unten stehende
            Kontaktformular aus oder rufen Sie uns an unter 0581 94879080.
          </p>
          <form ref={form} onSubmit={sendEmail}>
            <input
              type="text"
              name="username"
              placeholder="Name"
              value={formData.username}
              onChange={handleInputChange}
            />
            {formData.errors.username && (
              <span className="error">{formData.errors.username}</span>
            )}
            <input
              type="email"
              name="email"
              placeholder="E-Mail"
              value={formData.email}
              onChange={handleInputChange}
            />
            {formData.errors.email && (
              <span className="error">{formData.errors.email}</span>
            )}
            <input
              type="number"
              name="number"
              placeholder="Mobil"
              value={formData.number}
              onChange={handleInputChange}
            />
            {formData.errors.number && (
              <span className="error">{formData.errors.number}</span>
            )}
            <textarea
              name="message"
              id=""
              cols="30"
              rows="10"
              placeholder="Nachricht"
              value={formData.message}
              onChange={handleInputChange}
            ></textarea>
            {formData.errors.message && (
              <span className="error">{formData.errors.message}</span>
            )}
            {result ? null : (
              <button disabled={loading}>
                {loading ? <Loading /> : "Einreichen"}
              </button>
            )}
            <div className="row">
              {result ? (
                <div className="result">
                  <p>
                    <CheckCircleIcon />
                  </p>
                  <p className="result-email">"E-Mail erfolgreich gesendet"</p>
                </div>
              ) : null}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Section3;
