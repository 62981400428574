import React from "react";
import "./banner.scss";
import DeliveryDiningOutlinedIcon from "@mui/icons-material/DeliveryDiningOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import SpaOutlinedIcon from "@mui/icons-material/SpaOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import IconButton from "@mui/material/IconButton";

const Banner = () => {
  return (
    <div className="banner">
      <div className="container">
        <div className="banner-wrapper">
          {/* <div className="banner-item">
            <span>
              <DeliveryDiningOutlinedIcon />
            </span>
            <span>Wir liefern zu Ihnen nach Hause</span>
            <p>
              Wir bieten Ihnen zuverlässige und sichere Lieferung direkt zu
              Ihnen nach Hause an.
            </p>
          </div> */}
          <div className="banner-item">
            <span>
              <FavoriteBorderOutlinedIcon />
            </span>
            <span>Folgen Sie uns in den sozialen Medien</span>
            <p>
              Oder abonnieren Sie unseren Newsletter, um auf dem Laufenden zu
              bleiben.
            </p>
          </div>

          <div className="banner-item">
            <span>
              <EmailOutlinedIcon />
            </span>
            <span>Wir freuen uns von Ihnen zu hören</span>
            <p>
              Ob Feedback oder wenn Sie unserem liebenswerten Team beitreten
              möchten
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
