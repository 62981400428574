import React from "react";
import "./reservation.scss";
import LocalBarIcon from "@mui/icons-material/LocalBar";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";

const Reservation = () => {
  return (
    <div className="reservation">
      <div className="container">
        <div className="reservation__wrapper">
          <div className="reservation__item__img">
            <span>
              <LocalBarIcon />
            </span>
            <span>Jetzt anfragen</span>
            <p>Jetzt anfragen</p>
          </div>
          <div className="reservation__item">
            <p>Wir haben geöffnet</p>
            <span>Montag Ruhetag</span>
            <span>Dienstag bis Donnerstag 11:30–14:00, 17:30–21:30</span>
            <span>Freitag und Samstag 11:30–14:00, 17:30–22:00</span>
            <span>Sonntag 11:30–14:00, 17:30–21:00</span>
          </div>
          <div className="reservation__item__img2">
            <span>
              <ShoppingBagIcon />
            </span>
            <h2>Restaurant Opatija zum Mitnehmen</h2>
            <p>Take-Out bestellen</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reservation;
