import React, { useEffect } from "react";
import Section1 from "../../components/contact/Section-1/Section1";
import Section2 from "../../components/contact/Section-2/Section2";
import Section3 from "../../components/contact/Section-3/Section3";
import Section4 from "../../components/contact/Section-4/Section4";

const Contact = () => {
  useEffect(() => {
    document.title = "Kontakt - Restaurant Opatija";
  }, []);
  return (
    <div>
      <Section1 />
      {/* <Section2 /> */}
      <Section3 />
      <Section4 />
    </div>
  );
};

export default Contact;
