import React, { useContext } from 'react';
import './index.scss'
import { createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom';
import Home from './pages/home/Home';
import Info from './components/home/info/Info';
import Navbar from './components/home/navbar/Navbar';
import Footer from './components/home/footer/Footer';
import Contact from './pages/contact/Contact.jsx'
// import Booking from './pages/booking/Booking';
import About from './pages/about/About';
import Menu from './pages/menu/Menu';
import Impressum from './pages/privacy/Impressum.jsx';
import { DarkModeContext } from './Context/darkModeContext.js';



function App() {

const {darkMode} = useContext(DarkModeContext)


  const Layout = () => {
    return (
      <div className={darkMode ? 'app dark' : 'app'}>
        <Info />
        <Navbar />
        <div>

          <div><Outlet /></div>

        </div>
        <Footer />
      </div >

    )
  }



  const router = createBrowserRouter([
    {
      path: '/',
      element: <Layout />,
      children: [
        {
          path: '/',
          element: <Home />,
        },
        {
          path: '/kontakt',
          element: <Contact />,
        },
        // {
        //   path: '/tisch-reservieren',
        //   element: <Booking />,
        // },
        {
          path: '/über',
          element: <About />,
        },
        {
          path: '/menü',
          element: <Menu />,
        },
{
          path: '/impressum-datenschutz',
          element: <Impressum />,
        },
      ],
    },


  ])



  return (
    <div >
      <RouterProvider router={router} />
    </div>
  );
}


export default App


