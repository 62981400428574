import React from "react";
import aboutsection1 from "../../../static/about-section1.webp";
import "./section1.scss";

const Section1 = () => {
  return (
    <div className="section-1-about">
      <div className="section-1-wrapper-about">
        <div className="section-1-text-about">
          <p>Willkommen im Restaurant Opatija! </p>
          <p>
            Das Restaurant Opatija heißt Sie herzlich willkommen zu einer
            kulinarischen Reise, die Ihre Sinne verzaubern wird. Seit unserer
            Gründung im Jahr 2024 haben wir es uns zur Aufgabe gemacht, Ihnen
            ein unvergessliches gastronomisches Erlebnis zu bieten.
          </p>
          <p>
            Unser talentiertes Küchenteam unter der Leitung unseres erfahrenen
            Küchenchefs verwöhnt Ihre Geschmacksknospen mit einer vielfältigen
            Auswahl an Gerichten aus der mediterranen und kroatischen Küche. Von
            köstlichen Meeresfrüchten bis hin zu herzhaften Fleischgerichten -
            bei uns ist für jeden Geschmack etwas dabei.
          </p>
          <p>
            Neben unserer erstklassigen Küche legen wir großen Wert auf einen
            hervorragenden Service und eine gemütliche Atmosphäre. Unser
            aufmerksames und freundliches Personal steht Ihnen jederzeit zur
            Verfügung, um sicherzustellen, dass Ihr Besuch im Restaurant Opatija
            zu einem unvergesslichen Erlebnis wird.
          </p>
          <p>
            Wir laden Sie herzlich ein, uns zu besuchen und sich selbst von
            unserem einzigartigen gastronomischen Angebot zu überzeugen.
            Genießen Sie einen unvergesslichen Abend im Restaurant Opatija, wo
            Qualität, Geschmack und Gastfreundschaft an erster Stelle stehen.
          </p>
        </div>
        <div className="section-1-image-about">
          <div className="text-image">
            <h3>Über uns</h3>
          </div>
          <img src={aboutsection1} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Section1;
