import React from "react";
import "./featured2.scss";
import opatija from "../../../static/opatija-6.webp";
import { Link } from "react-router-dom";

const Featured2 = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };
  return (
    <div className="featured2">
      <div className="container">
        <div className="featured2-wrapper">
          <div className="featured2-text">
            <p>Herzlich Willkommen im</p>
            <br />
            <h3>
              Restaurant Opatija in Uelzen. Erleben Sie mit uns eine
              außergewöhnliche kulinarische Reise in den Balkan. Unsere
              authentische Küche vereint Menschen, welche die Geschichte und
              ihre Traditionen in einer warmen und freundlichen Atmosphäre
              verbinden. Stand
            </h3>
          </div>
        </div>
        <div className="featured2-items">
          <div className="featured2-img">
            <img src={opatija} alt="" />
          </div>
          <div className="featured2-text__items">
            <span>ÜBER UNS</span>
            <h3>Kulinarische Philosophie</h3>
            <p>
              Im Restaurant Opatija spiegelt unsere dynamische Speisekarte
              täglich das Beste aus unserem lokalen Marktangebot wider.
              Verankert in einer leidenschaftlichen Verpflichtung, frische,
              hochwertige Zutaten von vertrauten Bauern und Lieferanten zu
              beziehen, dreht sich unsere kulinarische Ethik darum,
              traditionelle Aromen harmonisch zu verbinden. Unsere Mission und
              größte Freude besteht darin, vertraute Rezepte in außergewöhnliche
              Küche zu verwandeln und damit das Speiseerlebnis für unsere
              anspruchsvollen Gäste zu bereichern.
            </p>
            <Link className="link" to={`/über`}>
              <button onClick={scrollToTop}>Erfahren Sie mehr</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Featured2;
