import React from "react";
import menusection1 from "../../../static/inquire-photos.webp";
import "./section1.scss";

const Section1 = () => {
  return (
    <div className="section-1-menu">
      <div className="section-1-wrapper-menu">
        <div className="section-1-image-menu">
          <div className="text-image">
            <h3>
              Restaurant Opatija <br /> Erfahrung
            </h3>
          </div>
          <img src={menusection1} alt="" />
        </div>
        <div className="section-1-text-menu">
          <h2>Menü</h2>
          <p>
            „Unsere Speisekarte repräsentiert das Beste, was unsere lokalen
            Märkte zu bieten haben. Unsere Leidenschaft für die Beschaffung
            frischer Zutaten von Bauern und Lieferanten, die wir kennen,
            kombiniert mit traditionellen Aromen und der Neuinterpretation
            bekannter Rezepte, um außergewöhnliche Küche zu kreieren, ist unsere
            Mission und die größte Freude.“
          </p>
        </div>
      </div>
    </div>
  );
};

export default Section1;
