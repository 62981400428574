import React from "react";

const Section1 = () => {
  return (
    <div className="section-privacy-1">
      <div className="container">
        <div className="text-privacy-1">
          <h1>IMPRESSUM / DATENSCHUTZ</h1>
        </div>
      </div>
    </div>
  );
};

export default Section1;
